body {
  overflow: hidden;
  user-select: none;
}

body, html {
  margin: 0;
  padding: 0;
}

canvas {
  width: 100%;
  height: 102%;
  display: none;
  position:absolute;
  top:0px;
  left:0px;
  z-index: 1000001;
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: unset !important;
}

.background-image {
  position: fixed;
  top: -1%;
  left: -1%;
  width: 102%;
  height: 102%;
  object-fit: cover;
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 28, 28, 0.559);
}

.button {
  position: relative;
  display: inline-block;
  border: 1.5px solid rgba(255, 255, 255, 0.7);
  border-radius: 100%;
  color: white;
  text-decoration: none;
  background-color: #18181865;
  backdrop-filter: blur(10px);
  width: 8rem;
  height: 8rem;
  line-height: 0.8rem;
  text-align: center;
  cursor: pointer;
  animation: reveal 0.65s;
  margin-top: -3rem;
  z-index: 10000;
}

@media (min-width: 1024px) {
  .button {
    width: 10rem;
    height: 10rem;
    line-height: 1rem;
  }
}

@media (min-width: 1200px) {
  .button {
    width: 12rem;
    height: 12rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .button {
    width: 14rem;
    height: 14rem;
    line-height: 1.8rem;
  }
}

.button:hover {
  /* background-color: #18181880; */
  background-color: #cdcdcd;
  color: black;
}

.sending-otp {
  background-color: #cdcdcd !important;
  color: black !important;
}

.home-form {
  min-width: 20rem;
}

.form, .how-to-play {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  z-index: 1;
  background-color: #18181865;
  border: 0.05rem solid rgb(74, 74, 74) !important;
  backdrop-filter: blur(5px);
  padding: 3rem 3rem 3rem 3rem;
  border-radius: 0.5rem;
  animation: reveal 0.5s;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}

.form label, .feedback label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  color : white;
  width : 100%;
}

.form input, .feedback textarea {
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0.087);
  backdrop-filter: blur(10px);
  color: white;
  /* width: 100%; */
  margin-bottom: 0.35rem;
}

.form input::placeholder, .feedback textarea::placeholder {
  color: white;
  opacity: 0.7;
}

.form-header {
  color: white;
  margin-bottom: 3rem;
}

.button-alt {
  position: relative;
  display: inline-block;
  border: 1.5px solid rgba(255, 255, 255, 0.7);
  border-radius: 0.3rem;
  color: white;
  text-decoration: none;
  background-color: #18181810;
  backdrop-filter: blur(10px);
  width: 100%;
  height: auto;
  margin-top: 2rem;
  padding: 0.75rem 3rem !important;
  /*line-height: 25px;
  */text-align: center;
  cursor: pointer;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
}

.button-alt:hover {
  background-color: #cdcdcd;
  color: black;
}

@keyframes reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.how-to-play {
  width: 14rem;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #18181865;
  border: 1px solid white;
}

@media (min-width: 600px) {
  .how-to-play {
    width: 24rem;
    position: absolute;
    position: absolute;
    top: 11% !important;
    left: 72.5%;
    transform: translate(0);
}
  .second-btn {
    right: 8rem !important;
  }
}

.htp-images {
  width: 100%;
}

.htp-images-cont {
  display: inline-block;
  width: 50%;
  margin: auto;
  text-align: center;
}

.htp-image {
  width: 3rem;
  margin-bottom: 0.3rem;
}

.overlay-button {
  position: relative;
  display: inline-block;
  border: 1.5px solid rgba(255, 255, 255, 0.7);
  border-radius: 100%;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  background-color: #18181865;
  backdrop-filter: blur(10px);
  width: 2rem;
  height: 2rem;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  animation: reveal 0.5s;
  user-select: none;
}

.overlay-button:hover {
  background-color: #cdcdcd;
}

@media (min-width: 600px) {
  .overlay-button {
    width: 3rem;
    height: 3rem;
    top: 2rem;
    right: 2rem;
    animation: reveal 0.5s;
  }
}

@media (min-width: 1400px) {
  .overlay-button {
    width: 3rem;
    height: 3rem;
    top: 2rem;
    right: 2rem;
    animation: reveal 0.5s;
  }
}

@media (min-width: 768px) {
  .htp-content {
    margin-top: 2rem !important;
  }
}

@media (min-width: 1000px) {
  .htp-content {
    margin-top: 3rem !important;
  }
}

.logo img {
  position: absolute;
  animation: reveal 0.5s;
  width: calc(1.85rem * 3);
  top: 1rem;
  left: 1rem;
}

@media (min-width: 600px) {
  .logo img {
    width: calc(2.65rem * 3);
    top: 2rem;
    left: 2rem;
    animation: reveal 0.5s;
  }
}

@media (min-width: 1400px) {
  .logo img {
    width: calc(3rem * 3);
    top: 2rem;
    left: 2rem;
    animation: reveal 0.5s;
  }
}

.feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  width: 100%;
}

.feedback-container {
  position: absolute;
  width: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  background-color: #18181865;
  border: 1px solid white;
  backdrop-filter: blur(10px);
  padding: 2rem 3rem 2rem 3rem;
  border-radius: 10px;
  animation: reveal 0.5s;
  -webkit-animation: reveal 0.5s;
}

@media (min-width: 768px) {
  .feedback-container {
    width: 24rem;
  }  
}

.overlay-btn-img-1 {
  width: 0.95rem;
}

.overlay-btn-img-2 {
  width: 1rem;
}

@media (min-width: 600px) {
  .overlay-btn-img-1 {
    width: 1.2rem;
  }
  
  .overlay-btn-img-2 {
    width: 1.25rem;
  }
}

@media (min-width: 1400px) {
  .overlay-btn-img-1 {
    width: 1.5rem;
  }
  
  .overlay-btn-img-2 {
    width: 1.55rem;
  }
}

.fun-fact-container {
  position: absolute;
  margin: auto;
  animation: fadeIns 1s;
  width: 70%;
}

.fun-fact-text {
  color: white;
  opacity: 0.5;
  margin-top: 15rem;
  letter-spacing: 0.07rem;
}

.overlay-button-alt {
  position: relative;
  border: 1.5px solid rgba(72, 72, 72, 0.6);
  border-radius: 100%;
  color: rgba(255, 255, 255, 0.8);
  background-color: #18181865;
  backdrop-filter: blur(10px);
  width: 2rem;
  height: 2rem;
  text-align: center;
  cursor: pointer;
  animation: reveal 0.5s;
  user-select: none;
  margin: 0rem 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-container {
  background-color: #18181865;
  backdrop-filter: blur(1px);
  border: 1.5px solid rgba(72, 72, 72, 0.3);
  position: absolute;
  top: 4.5rem;
  right: 1rem;
  display: flex;
  padding: 0.5rem 0.3rem;
  border-radius: 5rem;
  -webkit-border-radius: 5rem;
  -moz-border-radius: 5rem;
  -ms-border-radius: 5rem;
  -o-border-radius: 5rem;
}

.help-modal {
  width: 20rem;
  position: absolute;
  top: 8.5rem !important;
  right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  background-color: #18181865;
  border: 0.05rem solid rgb(74, 74, 74) !important;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 2rem !important;
  border-radius: 0.5rem;
  animation: reveal 0.5s;
  -webkit-border-radius: 0.5rem;
  /* transform: translate(-50%, -50%); */
}

.header-logo {
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1111111;
}

.abhushan-logo-res {
  width: 3rem;
}

.xrv-logo-res {
  width: 6rem;
}

@media (min-width: 768px) {
  .menu-container {
    background-color: #18181865;
    backdrop-filter: blur(1px);
    border: 1.5px solid rgba(72, 72, 72, 0.3);
    position: absolute;
    top: 2rem;
    right: 6rem;
    display: flex;
    padding: 0.5rem 0.3rem;
    border-radius: 5rem;
    -webkit-border-radius: 5rem;
    -moz-border-radius: 5rem;
    -ms-border-radius: 5rem;
    -o-border-radius: 5rem;
  }

  .fun-fact-container {
    position: absolute;
    margin: auto;
    animation: fadeIns 1s;
  }
  
  .fun-fact-text {
    color: white;
    opacity: 0.5;
    margin-top: 20rem;
    letter-spacing: 0.07rem;
  }

  .help-modal {
    width: 24rem;
    position: absolute;
    top: 6.5rem !important;
    right: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    background-color: #18181865;
    border: 0.05rem solid rgb(74, 74, 74) !important;
    backdrop-filter: blur(5px);
    padding: 2.5rem !important;
    border-radius: 0.5rem;
    animation: reveal 0.5s;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
    transform: translate(0%, 0%);
  }

  .header-logo {
    position: absolute;
    top: 1.5rem;
    left: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1111111;
  }

  .abhushan-logo-res {
    width: calc(1.35rem * 3);
  }
  
  .xrv-logo-res {
    width: calc(2.15rem * 3);
  }
}

.logo, .menu {
  z-index: 1000002;
}

.dropdown {
  position: relative;
  display: inline-block;
  border: 1.5px solid rgba(255, 255, 255, 0.7);
  border-radius: 0.5rem;
  color: white;
  text-decoration: none;
  background-color: #18181865;
  backdrop-filter: blur(10px);
  width: 100%;
  line-height: 0.8rem;
  text-align: center;
  cursor: pointer;
  animation: reveal 0.65s;
  margin-top: -3rem;
  z-index: 10000;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  font-family: "Clash Display Medium";
  margin-top: 1rem;
}

.dropdown-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-body {
  padding: 5px;
  border-top: 1px solid #E5E8EC;
  display: none;
}

.dropdown-body.open {
  display: block;
}

.dropdown-item {
  padding: 10px;
  font-size: 0.9rem !important;
  text-align: left;
  color: #c4c4c4
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-item-dot {
  opacity: 0;
  color: #ffffff;
  transition: all .2s ease-in-out;
}

.dropdown-item-dot.selected {
  /* opacity: 1; */
}

.icon {
  font-size: 13px;
  color: #c4c4c4;
  transform: rotate(0deg);
  transition: all .2s ease-in-out;
}

.icon.open {
  transform: rotate(90deg);
}
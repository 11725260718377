/* FONT FAMILIES */
@font-face {
  font-family: Clash Display ExtraLight;
  src: url("https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/ClashDisplay-Extralight.ttf");
}

@font-face {
  font-family: Clash Display Light;
  src: url("https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/ClashDisplay-Light.ttf");
}

@font-face {
  font-family: Clash Display Medium;
  src: url("https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/ClashDisplay-Medium.ttf");
}

@font-face {
  font-family: Clash Display SemiBold;
  src: url("https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/ClashDisplay-Semibold.ttf");
}

@font-face {
  font-family: Clash Display Bold;
  src: url("https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/ClashDisplay-Bold.ttf");
}

@font-face {
  font-family: Klarissa Regular;
  src: url("https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/klarissa.regular.ttf");
}

@font-face {
  font-family: Klarissa Shadow;
  src: url("https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/klarissa.shadow.ttf");
}

@font-face {
  font-family: Klarissa Contour;
  src: url("https://xrv-xrc.s3.ap-south-1.amazonaws.com/Butati/klarissa.contour.ttf");
}

.ff-xl {
  font-family: "Clash Display ExtraLight";
}

.ff-l {
  font-family: "Clash Display Light";
}

.ff-m {
  font-family: "Clash Display Medium";
}

.ff-sb {
  font-family: "Clash Display SemiBold";
}

.ff-b {
  font-family: "Clash Display Bold";
}

.ff-kc {
  font-family: "Klarissa Contour";
}

.ff-kr {
  font-family: "Klarissa Regular";
}

.ff-ks {
  font-family: "Klarissa Shadow";
}



/* RESPONSIVE FONT SIZES */
.fs-xxs {
  font-size: 0.4261157914rem !important;
}

.fs-xs {
  font-size: 0.4738324949rem !important;
}

.fs-sm {
  font-size: 0.5685990529rem !important;
}

.fs-m {
  font-size: 0.7108259061rem !important;
}

.fs-lg {
  font-size: 0.9476650898rem !important;
}

.fs-xl {
  font-size: 1.4216518123rem !important;
}

.fs-xxl {
  font-size: 2.36916346rem !important;
}

@media (min-width: 425px) {
  .fs-xxs {
    font-size: 0.4734619904rem !important;
  }

  .fs-xs {
    font-size: 0.5264805499rem !important;
  }

  .fs-sm {
    font-size: 0.6317767254rem !important;
  }

  .fs-m {
    font-size: 0.7898065623rem !important;
  }

  .fs-lg {
    font-size: 1.0529610998rem !important;
  }

  .fs-xl {
    font-size: 1.5796131247rem !important;
  }

  .fs-xxl {
    font-size: 2.6324038444rem !important;
  }
}

@media (min-width: 576px) {
  .fs-xxs {
    font-size: 0.4983810425rem !important;
  }

  .fs-xs {
    font-size: 0.5541900525rem !important;
  }

  .fs-sm {
    font-size: 0.6650283425rem !important;
  }

  .fs-m {
    font-size: 0.8313753288rem !important;
  }

  .fs-lg {
    font-size: 1.108380105rem !important;
  }

  .fs-xl {
    font-size: 1.662562997rem !important;
  }

  .fs-xxl {
    font-size: 2.7709503625rem !important;
  }
}

@media (min-width: 768px) {
  .fs-xxs {
    font-size: 0.52482215rem !important;
  }

  .fs-xs {
    font-size: 0.58335795rem !important;
  }

  .fs-sm {
    font-size: 0.70002973rem !important;
  }

  .fs-m {
    font-size: 0.875131925rem !important;
  }

  .fs-lg {
    font-size: 1.1667159rem !important;
  }

  .fs-xl {
    font-size: 1.75016985rem !important;
  }

  .fs-xxl {
    font-size: 2.91678975rem !important;
  }
}

@media (min-width: 992px) {
  .fs-xxs {
    font-size: 0.5526549rem !important;
  }

  .fs-xs {
    font-size: 0.614061rem !important;
  }

  .fs-sm {
    font-size: 0.7368734rem !important;
  }

  .fs-m {
    font-size: 0.9211915rem !important;
  }

  .fs-lg {
    font-size: 1.228122rem !important;
  }

  .fs-xl {
    font-size: 1.842283rem !important;
  }

  .fs-xxl {
    font-size: 3.070305rem !important;
  }
}

@media (min-width: 1200px) {
  .fs-xs {
    font-size: 0.756rem !important;
  }

  .fs-sm {
    font-size: 0.9072rem !important;
  }

  .fs-m {
    font-size: 1.134rem !important;
  }

  .fs-lg {
    font-size: 1.512rem !important;
  }

  .fs-xl {
    font-size: 2.268rem !important;
  }

  .fs-xxl {
    font-size: 3.78rem !important;
  }
}

@media (min-width: 1400px) {
  .fs-xxs {
    font-size: 0.9rem !important;
  }

  .fs-xs {
    font-size: 1rem !important;
  }

  .fs-sm {
    font-size: 1.2rem !important;
  }

  .fs-m {
    font-size: 1.5rem !important;
  }

  .fs-lg {
    font-size: 2rem !important;
  }

  .fs-xl {
    font-size: 3rem !important;
  }

  .fs-xxl {
    font-size: 5rem !important;
  }
}
.butati-response-container {
  position: absolute;
  width: 70%;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items:center;
  /* background-color: red; */
  z-index: 1000003;
}

.butati-bot-container {
  position: absolute;
  width: 30%;
  height: 22rem;
  bottom: 0;
  right: 0;
  /* background-color: rgb(255, 255, 255); */
}

@media (min-width: 1800px) {
  .butati-bot-container {
    height: 25rem;
  }
}

@media (min-width: 1400px) {
  .butati-response-container {
    left: 2.5rem;
  }
}

@media (min-width: 1600px) {
  .butati-response-container {
    left: 3.5rem;
  }
}

@media (min-width: 1800px) {
  .butati-response-container {
    left: 4rem;
  }
}

.res-container {
  height: 7.5rem;
  width: 90%;
  margin: 0 5% 0.5rem 5%;
  background-color: rgba(27, 27, 27, 0.2);
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  display: flex;
  align-items: center;
  padding: 1rem 0rem;
}

.recBtn-container {
  width: 15%;
  /* background-color: green; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.25rem;
}

.res-data {
  color: white;
  letter-spacing: 0.05rem;
  word-spacing: 0.1rem;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 1rem;
  overflow: auto;
  margin-right: 0.5rem;
  width: 75%;
  padding: 0.5rem;
}


.header-cont {
  height: 10vh;
  /* display: flex;
  justify-content: end;
  align-items: center; */
  padding: 0rem 1rem;
}

.dropdown-cont {
  border-radius: 0.3125rem;
  background: transparent;
  color: #fff;
  padding: 0.25rem 1.75rem 0.25rem 0.65rem;
  position: absolute;
  width: fit-content;
  right: 1rem;
  margin-top: 2rem;
  z-index: 115;
  border: 0.05rem solid #3f3f3f;
  cursor: pointer;
}

.lang-option {
  /* padding: 0.25rem 1.75rem 0.25rem 0.65rem; */
  width: 100%;
  font-size: 0.9rem;
}

.dropdown-cont.active {
  /* styles for when the dropdown is open */
  padding: 0 0 0.1rem 0 !important;
}

.dropdown-cont.active ul {
  padding: 0.5rem 0.5rem;
  margin: 0;
  list-style: none;
}

.dropdown-cont.active ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 2.5rem;
  padding-left: 0.5rem;
  padding-top: 0.1rem;
  letter-spacing: 0.075rem;
  cursor: pointer;
}

.dropdown-cont.active ul li:nth-child(2) {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 2.5rem;
  margin-top: 0.3rem;
  padding-left: 0.5rem;
  letter-spacing: 0.075rem;
  font-weight: 900;
  padding-top: 0.2rem;
}

.dropdown-cont.active ul li:hover {
  background: #391f67;
}

.dropdown-image {
  width: 0.5rem;
  position: absolute;
  right: 15%;
  top: 55%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.selected-lang {
  font-size: 0.8rem;
  letter-spacing: 0.075rem;
}

.bot-cont {
  height: 100%;
}

.response-cont {
  overflow-y: scroll;
  position: relative;
  z-index: 100;
  height: 22.5vh;
  -webkit-mask-image: linear-gradient(to bottom, black 60%, transparent);
  mask-image: linear-gradient(to bottom, black 60%, transparent);
}

.response-cont.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recording-cont {
  height: 17.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.gc-response {
  color: white;
  opacity: 0.7;
  word-spacing: 0.08rem;
  letter-spacing: 0.065rem;
  /* font-style: italic; */
  text-align: center;
  padding: 0rem 3rem 2rem 3rem;
  font-size: 0.95rem;
  line-height: 1.3rem;
}

@media (min-width: 768px) {
  .response-cont {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gc-response {
    padding: 0rem 3rem;
  }
  .header-cont {
    padding: 0rem 3rem;
  }
  .dropdown-cont {
    right: 2rem;
  }
  .butati-bot-container {
    width: 23%;
  }
  .butati-response-container {
    width: 77%;
  }
  .res-container {
    height: 7.5rem;
    width: 90%;
    margin: 0 5% 2rem 5%;
    background-color: rgba(27, 27, 27, 0.2);
    border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
    display: flex;
    align-items: center;
    padding: 1rem 0rem;
  }
  .res-data {
    color: white;
    letter-spacing: 0.05rem;
    word-spacing: 0.1rem;
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 3rem;
    overflow: auto;
    width: 80%;
    margin-right: 0rem;
    padding: 0rem;
  }
  .recBtn-container {
    width: 20%;
    margin-right: 0rem;
  }
}

@media (min-width: 1200px) {
  .gc-response {
    padding: 0rem 8rem;
    margin-top: 1rem;
    font-size: 1rem;
  }
}

@media (min-width: 1400px) {
  .gc-response {
    padding: 0rem 10rem;
    /* margin-top: 1.5rem;/ */
  }
}

@media (min-width: 1600px) {
  .gc-response {
    padding: 0rem 12rem;
    /* margin-top: 2rem; */
  }
}


.ui-rec-btn {
  padding: 0.475rem 0.6925rem 0.475rem 0.6925rem;
  border-radius: 100%;
  cursor: pointer;
  outline: inherit;
  color: #fff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 3.125rem;
  background: radial-gradient(218.89% 191.66% at 50.15% 0.00%, #cb3741ec 0%, #cb3741ed 120%);
  border: 0.3rem solid #832a319e;
  box-shadow: 0px 10px 70px 3px #cb232e;
  margin-top: -1.5rem !important;
}

@media (min-width: 768px) {
  .ui-rec-btn {
    padding: 0.8925rem 0.8925rem 0.85rem 0.8925rem;
  }
}

.ui-rec-btn:active {
  background: radial-gradient(218.89% 191.66% at 50.15% 0.00%, #cb3741ec 0%, #cb3741ed 120%);
}

.ui-rec-btn:active,
.ui-rec-btn:focus {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

@keyframes beat {
  0% {
    box-shadow: 0px 10px 100px 3px #cb232e;
    border: 0.3rem solid #832a319e;
  }
  50% {
    box-shadow: 0px 10px 100px 1px #cb232e;
    border: 1rem solid #832a319e;
  }
  100% {
    box-shadow: 0px 10px 100px 3px #cb232e;
    border: 0.3rem solid #832a319e;
  }
}

.ui-rec-btn.active {
    animation: beat 1.5s infinite;
    -webkit-animation: beat 1.5s infinite;
}

.info-text {
  color: #ffffff;
  margin-top: 3.5rem !important;
  word-spacing: 0.05rem;
  letter-spacing: 0.02rem;
  font-style: italic;
  font-size: 0.5rem;
  position: absolute;
}

.loading-screen {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: #000000;
  z-index: 130;
}

.load-start-cont {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.loading-bar {
  position: relative;
  /* width: 20rem; */
  width: 12rem;
}

.xrv-logo-img {
  width: 7rem;
}

.start-button {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  border-radius: 2rem;
  border: 4px solid rgba(83, 45, 178, 0.60);
  background: radial-gradient(218.89% 191.66% at 50.15% 0.00%, rgba(99, 51, 221, 0.9) 0%, #AF6CFC 100%);
  box-shadow: 2px 2px 30px 3px #532DB2;
  color: white;
  cursor: pointer;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
}

@media (min-width: 350px) {
  .loading-bar {
    width: 15rem;
  }
  .xrv-logo-img {
    width: 7.5rem;
  }
}

@media (min-width: 768px) {
  .loading-bar {
    width: 18rem;
  }
  .xrv-logo-img {
    width: 9rem;
  }
  .start-button {
    font-size: 1.15rem;
  }
  .info-text {
    color: #ffffff;
    margin-top: 4.5rem !important;
    word-spacing: 0.05rem;
    letter-spacing: 0.02rem;
    font-style: italic;
    font-size: 0.8rem;
    position: absolute;
  }
}

.start-button:hover {
  background: radial-gradient(218.89% 191.66% at 50.15% 0.00%, rgba(99, 51, 221, 1) 0%, #AF6CFC 100%);
  box-shadow: 2px 2px 35px 4px #532DB2;
}

.xrv-logo {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  text-align: center;
}

.xrv-logo-text {
  color: #fff;
  font-size: 0.75rem;
  margin-bottom: 2rem;
  font-style: italic;
}


.navigation-ui {
  /* position: absolute; */
  /* top: 6.5rem; */
  /* right: 2rem; */
  /* min-width: 15rem; */
  color: white;
  /* border-radius: 1rem; */
  /* -webkit-border-radius: 1rem; */
  /* -moz-border-radius: 1rem; */
  /* -ms-border-radius: 1rem; */
  /* -o-border-radius: 1rem; */
}

.navigation-ui > .MuiBox-root > ul {
  /*background: #00000040;
  *//*border-radius: 0.5rem;
  *//*-webkit-border-radius: 0.5rem;
  *//*-moz-border-radius: 0.5rem;
  *//*-ms-border-radius: 0.5rem;
  *//*-o-border-radius: 0.5rem;
  *//*padding: 1rem;
  */position: absolute;
  top: 8.5rem;
  right: 1rem;
  color: white;
  min-width: 15rem;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: #18181865;
  border: 0.05rem solid rgb(74, 74, 74) !important;
  backdrop-filter: blur(5px);
  padding: 1rem;
  border-radius: 0.5rem;
  animation: reveal 0.5s;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  -webkit-animation: reveal 0.5s;
}

@media (min-width: 768px) {
  .navigation-ui > .MuiBox-root > ul {
    /*background: #00000040;
    *//*border-radius: 0.5rem;
    *//*-webkit-border-radius: 0.5rem;
    *//*-moz-border-radius: 0.5rem;
    *//*-ms-border-radius: 0.5rem;
    *//*-o-border-radius: 0.5rem;
    *//*padding: 1rem;
    */position: absolute;
    top: 6.5rem;
    right: 2rem;
    color: white;
    min-width: 15rem;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background-color: #18181865;
    border: 0.05rem solid rgb(74, 74, 74) !important;
    backdrop-filter: blur(5px);
    padding: 1rem;
    border-radius: 0.5rem;
    animation: reveal 0.5s;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
    -webkit-animation: reveal 0.5s;
  }
}

.MuiTreeItem-root > .MuiTreeItem-content > .MuiTreeItem-iconContainer {
  /* display: none; */
}

.MuiTreeItem-label {
  font-family: 'Clash Display Medium' !important;
}

.sceneNameCont {
  margin: 0.75rem;
  background-color: #00000050;
  border-radius: 10rem;
}

.Mui-selected {
  background: radial-gradient(218.89% 191.66% at 50.15% 0.00%, #cb3741b6 0%, #cb37417d 120%) !important;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
}

/* .MuiTreeItem-iconContainer {
  display: none;
} */

.MuiTreeItem-label{
  padding: 0.25rem 1rem 0.25rem 0rem !important;
  font-size: 0.7rem !important;
}

@media (min-width: 768px) {
  .MuiTreeItem-label{
    padding: 0.35rem 1rem 0.35rem 0rem !important;
    font-size: 0.8rem !important;
  }
}

.bot-cont > div > div > canvas {
  pointer-events: none;
}